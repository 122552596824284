import React from "react";
import { Routes as ReactRouterRoutes, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { userRolesPermissions } from "../hoc/userRolesPermissions";
import Home from "./Home";
import Layout from "./Layout";
import AllocateOfferView from "./allocateoffer/AllocateOfferView";
import BarcodeBlocklistDashboard from "./barcodeblocklist/BarcodeBlocklistDashboard";
import CreateBarcodeBlocklist from "./barcodeblocklist/CreateBarcodeBlocklist";
import EditBarcodeBlocklist from "./barcodeblocklist/EditBarcodeBlocklist";
import CampaignDashboard from "./campaign/CampaignDashboard";
import CampaignUploads from "./campaign/CampaignUploads";
import CreateCampaign from "./campaign/CreateCampaign";
import EditCampaign from "./campaign/EditCampaign";
import CustomerDetailsView from "./customer/CustomerDetailsView";
import CustomersView from "./customer/CustomersView";
import AccessDeniedView from "./error/AccessDeniedView";
import NotFoundView from "./error/NotFoundView";
import FaqView from "./faq/FaqView";
import GS1SupplierCodeDashboard from "./gs1suppliercodes/GS1SupplierCodeDashboard";
import GS1SupplierCodesEditor from "./gs1suppliercodes/GS1SupplierCodesEditor";
import GS1SupplierCodesUsage from "./gs1suppliercodes/GS1SupplierCodesUsage";
import MarketingEventEditor from "./marketingEvents/MarketingEventEditor";
import MarketingEventIterations from "./marketingEvents/MarketingEventIterations";
import MarketingEventUIContents from "./marketingEvents/MarketingEventUIContents";
import MarketingEventUIOptions from "./marketingEvents/MarketingEventUIOptions";
import MarketingEventUserGroup from "./marketingEvents/MarketingEventUserGroup";
import MarketingEventsDashboard from "./marketingEvents/MarketingEventsDashboard";
import NectarActivityLabelEditor from "./nectarActivityLabels/NectarActivityLabelEditor";
import NectarActivityLabels from "./nectarActivityLabels/NectarActivityLabels";
import HomepagePodsAssetManagement from "./podmanagement/HomepagePodsAssetManagement";
import HomepagePodsManagementView from "./podmanagement/HomepagePodsManagementView";
import PodEditor from "./podmanagement/PodEditor";
import ProductsView from "./product/ProductsView";
import CreatePromotion from "./promotions/CreatePromotion";
import EditPromotion from "./promotions/EditPromotion";
import PromotionDashboard from "./promotions/PromotionDashboard";
import PromotionUploads from "./promotions/PromotionUploads";
import SkuView from "./sku/SkuView";

const CmpgnView = ["CampaignEditor", "ReadOnly"];
const MEView = ["MarketingEventEditor", "ReadOnly"];
const HPView = ["HomepagePodEditor", "ReadOnly"];
const OCView = ["OfferCodeEditor", "ReadOnly"];

const CustomersViewWithPermissions = userRolesPermissions(CustomersView, [
  "CustomerDataViewer",
]);
const CampaignDashboardWithPermissions = userRolesPermissions(
  CampaignDashboard,
  CmpgnView
);
const CreateCampaignWithPermissions = userRolesPermissions(
  CreateCampaign,
  "CampaignEditor"
);
const EditCampaignWithPermissions = userRolesPermissions(
  EditCampaign,
  CmpgnView
);
const CampaignUploadsWithPermissions = userRolesPermissions(
  CampaignUploads,
  CmpgnView
);
const MarketingEventsDashboardWithPermissions = userRolesPermissions(
  MarketingEventsDashboard,
  MEView
);
const MarketingEventEditorWithPermissions = userRolesPermissions(
  MarketingEventEditor,
  "MarketingEventEditor"
);
const MarketingEventUserGroupWithPermissions = userRolesPermissions(
  MarketingEventUserGroup,
  "MarketingEventEditor"
);
const MarketingEventUIOptionsWithPermissions = userRolesPermissions(
  MarketingEventUIOptions,
  "MarketingEventEditor"
);
const MarketingEventUIContentsWithPermissions = userRolesPermissions(
  MarketingEventUIContents,
  "MarketingEventEditor"
);
const MarketingEventIterationsWithPermissions = userRolesPermissions(
  MarketingEventIterations,
  "MarketingEventEditor"
);
const CustomerDetailsViewWithPermissions = userRolesPermissions(
  CustomerDetailsView,
  "CustomerDataViewer"
);
const HomepagePodsManagementViewWithPermissions = userRolesPermissions(
  HomepagePodsManagementView,
  HPView
);
const PodEditorWithPermissions = userRolesPermissions(
  PodEditor,
  "HomepagePodEditor"
);
const HomepagePodsAssetManagementWithPermissions = userRolesPermissions(
  HomepagePodsAssetManagement,
  HPView
);
const ProductsViewWithPermissions = userRolesPermissions(
  ProductsView,
  CmpgnView
);
const PromotionDashboardWithPermissions = userRolesPermissions(
  PromotionDashboard,
  CmpgnView
);
const CreatePromotionWithPermissions = userRolesPermissions(
  CreatePromotion,
  "CampaignEditor"
);
const EditPromotionWithPermissions = userRolesPermissions(
  EditPromotion,
  "CampaignEditor"
);
const PromotionUploadsWithPermissions = userRolesPermissions(
  PromotionUploads,
  CmpgnView
);
const GS1SupplierCodeDashboardWithPermissions = userRolesPermissions(
  GS1SupplierCodeDashboard,
  CmpgnView
);
const GS1SupplierCodesEditorWithPermissions = userRolesPermissions(
  GS1SupplierCodesEditor,
  "CampaignEditor"
);
const GS1SupplierCodesUsageWithPermissions = userRolesPermissions(
  GS1SupplierCodesUsage,
  CmpgnView
);
const BarcodeBlocklistDashboardWithPermissions = userRolesPermissions(
  BarcodeBlocklistDashboard,
  CmpgnView
);
const CreateBarcodeBlocklistWithPermissions = userRolesPermissions(
  CreateBarcodeBlocklist,
  "CampaignEditor"
);
const EditBarcodeBlocklistWithPermissions = userRolesPermissions(
  EditBarcodeBlocklist,
  "CampaignEditor"
);
const AllocateOfferViewWithPermissions = userRolesPermissions(
  AllocateOfferView,
  "OfferAllocator"
);
const OfferCodeViewWithPermissions = userRolesPermissions(
  NectarActivityLabels,
  OCView
);

const NectarActivityLabelsPermissions = userRolesPermissions(
  NectarActivityLabelEditor,
  OCView
);
const SkuViewWithPermissions = userRolesPermissions(SkuView, MEView);

const Routes = () => (
  <ReactRouterRoutes>
    <Route
      path="/"
      element={
        <Layout>
          <Home />
        </Layout>
      }
    />
    <Route
      path="/customers"
      element={
        <Layout>
          <CustomersViewWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/campaigns"
      element={
        <Layout>
          <CampaignDashboardWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/campaigns/create"
      element={
        <Layout>
          <CreateCampaignWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/campaigns/edit/:campaignId"
      element={
        <Layout>
          <EditCampaignWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/campaigns/upload"
      element={
        <Layout>
          <CampaignUploadsWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/marketing-events"
      element={
        <Layout>
          <MarketingEventsDashboardWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/marketing-events/edit/:id"
      element={
        <Layout>
          <MarketingEventEditorWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/marketing-events/user-group"
      element={
        <Layout>
          <MarketingEventUserGroupWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/marketing-events/ui-options"
      element={
        <Layout>
          <MarketingEventUIOptionsWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/marketing-events/ui-contents"
      element={
        <Layout>
          <MarketingEventUIContentsWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/marketing-events/iterations"
      element={
        <Layout>
          <MarketingEventIterationsWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/customer/:externalHandle"
      element={
        <Layout>
          <CustomerDetailsViewWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/homepage-pods"
      element={
        <Layout>
          <HomepagePodsManagementViewWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/homepage-pods/edit/:id"
      element={
        <Layout>
          <PodEditorWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/homepage-pods/hp-assets"
      element={
        <Layout>
          <HomepagePodsAssetManagementWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/products"
      element={
        <Layout>
          <ProductsViewWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/promotions"
      element={
        <Layout>
          <PromotionDashboardWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/promotions/create"
      element={
        <Layout>
          <CreatePromotionWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/promotions/edit/:promotionId"
      element={
        <Layout>
          <EditPromotionWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/promotions/upload"
      element={
        <Layout>
          <PromotionUploadsWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/gs1suppliercodes"
      element={
        <Layout>
          <GS1SupplierCodeDashboardWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/gs1suppliercodes/create"
      element={
        <Layout>
          <GS1SupplierCodesEditorWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/gs1suppliercodes/view/:gs1Code"
      element={
        <Layout>
          <GS1SupplierCodesUsageWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/barcodeblocklists"
      element={
        <Layout>
          <BarcodeBlocklistDashboardWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/barcodeblocklists/create"
      element={
        <Layout>
          <CreateBarcodeBlocklistWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/barcodeblocklists/edit/:barcode"
      element={
        <Layout>
          <EditBarcodeBlocklistWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/allocateoffer"
      element={
        <Layout>
          <AllocateOfferViewWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/nectar-activity-labels"
      element={
        <Layout>
          <OfferCodeViewWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/nectar-activity-labels/edit/:id"
      element={
        <Layout>
          <NectarActivityLabelsPermissions />
        </Layout>
      }
    />
    <Route
      path="/skus"
      element={
        <Layout>
          <SkuViewWithPermissions />
        </Layout>
      }
    />
    <Route
      path="/faq"
      element={
        <Layout>
          <FaqView />
        </Layout>
      }
    />
    <Route path="/404" element={<NotFoundView />} />
    <Route path="/403" element={<AccessDeniedView />} />
    <Route path="*" element={<NotFoundView />} />
  </ReactRouterRoutes>
);

export default Routes;
