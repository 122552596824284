import { useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

import CampaignClient from "../../services/CampaignClient";
import CampaignEditor from "./CampaignEditor";
import { validCampaignTypes } from "./options";

const validCampaignTypeValues = validCampaignTypes.map((n) => n.value);

const campaignClient = new CampaignClient();

const EditCampaign = () => {
  const { accounts } = useMsal();
  const username = accounts?.[0]?.name;
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [apiErrorText, setApiErrorText] = useState("");
  const [initialValues, setInitialValues] = useState({});
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [loading, setLoading] = useState(false);

  const [existingTags, setExistingTags] = useState([
    "Loading available tags...",
  ]);

  useEffect(() => {
    const loadData = async () => {
      const loadTags = async () => {
        try {
          const response = await campaignClient.fetchCampaignTags();
          if (response.status !== 200) {
            throw new Error(response);
          }
          setExistingTags(response.data.map((n) => n.name));
        } catch (err) {
          console.error(err);
          const message = err?.response?.data?.description ?? "";
          setApiErrorText("Failed to get tags. " + message);
          setExistingTags(["Failed to get tags"]);
        }
      };

      const loadCampaign = async () => {
        try {
          const response = await campaignClient.fetchCampaign(campaignId);
          if (response.status !== 200) {
            throw new Error(response);
          }
          if (!validCampaignTypeValues.includes(response.data.campaignType)) {
            setApiErrorText(
              response.data.campaignType +
                " campaigns are not supported on this page"
            );
            return;
          }
          setInitialValues(response.data);
        } catch (err) {
          console.error(err);
          const message = err?.response?.data?.description ?? "";
          setApiErrorText("Failed to load campaign. " + message);
        }
      };
      await Promise.all([loadCampaign(), loadTags()]);

      setInitialLoadComplete(true);
    };
    loadData();
  }, [campaignId]);
  // if the id in the path changes, then this will re-run, but it should remount the component anyway.

  const onSave = async (requestBody, generateBarcode) => {
    setLoading(true);
    setApiErrorText(null);

    try {
      const newTags = (requestBody.tags ?? []).filter(
        (t) => !existingTags.includes(t)
      );
      for (const tag of newTags) {
        const response = await campaignClient.createCampaignTag(tag);
        if (response.status !== 200) {
          throw new Error(response);
        }
      }
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to save new campaign tags. " + message);
    }

    if (generateBarcode) {
      try {
        const response = await campaignClient.generateBarcode(requestBody);
        if (response.status !== 200) {
          throw new Error(response);
        }
        requestBody.barcode = response.data.barcode;
        toast.info("Created new barcode for campaign: " + requestBody.barcode);
      } catch (err) {
        console.error(err);
        const message = err?.response?.data?.description ?? "";
        setApiErrorText("Failed to generate a barcode. " + message);
      }
    }

    try {
      const response = await campaignClient.updateCampaign(
        campaignId,
        requestBody
      );
      if (response.status !== 200) {
        throw new Error(response);
      }
      toast.success(`Campaign ${campaignId} was successfully saved`);
      setLoading(false);
      onClose();
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to save the campaign. " + message);
      setLoading(false);
    }
  };

  const onClose = () => navigate("/campaigns");

  const onCommit = async () => {
    setLoading(true);
    try {
      const response = await campaignClient.commitCampaign(campaignId);
      if (response.status !== 200) {
        throw new Error(response);
      }
      toast.success(`Campaign ${campaignId} was successfully commited`);
      onClose();
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to commit the campaign. " + message);
    }
    setLoading(false);
  };

  const onPause = async () => {
    setLoading(true);
    try {
      const response = await campaignClient.pauseCampaign(campaignId);
      if (response.status !== 200) {
        throw new Error(response);
      }
      toast.success(`Campaign ${campaignId} was successfully paused`);
      onClose();
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to pause the campaign. " + message);
    }
    setLoading(false);
  };

  const onResume = async () => {
    setLoading(true);
    try {
      const response = await campaignClient.resumeCampaign(campaignId);
      if (response.status !== 200) {
        throw new Error(response);
      }
      toast.success(`Campaign ${campaignId} was successfully resumed`);
      onClose();
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to resume the campaign. " + message);
    }
    setLoading(false);
  };

  const onStop = async () => {
    setLoading(true);
    try {
      const response = await campaignClient.stopCampaign(campaignId);
      if (response.status !== 200) {
        throw new Error(response);
      }
      toast.success(`Campaign ${campaignId} was successfully stopped`);
      onClose();
    } catch (err) {
      console.error(err);
      const message = err?.response?.data?.description ?? "";
      setApiErrorText("Failed to stop the campaign. " + message);
    }
    setLoading(false);
  };

  if (!initialLoadComplete) {
    return (
      <ProgressIndicator page loading preventFocus>
        <ProgressBar color="light" />
        Loading...
      </ProgressIndicator>
    );
  }

  return (
    <CampaignEditor
      loading={loading}
      existingTags={existingTags}
      currentUsername={username}
      apiErrorText={apiErrorText}
      onSave={onSave}
      onCommit={onCommit}
      onResume={onResume}
      onPause={onPause}
      onStop={onStop}
      initialValues={initialValues}
      campaignId={campaignId}
    />
  );
};

export default EditCampaign;
