import { useMsal } from "@azure/msal-react";

import { useSessionContext } from "../contexts/SessionContext";

// Define a mapping of menu types to roles
export const roleMapping = {
  "marketing-events": "MarketingEventEditor",
  skus: "MarketingEventEditor",
  campaigns: "CampaignEditor",
  products: "CampaignEditor",
  promotions: "CampaignEditor",
  gs1suppliercodes: "CampaignEditor",
  barcodeblocklists: "CampaignEditor",
  offercodes: "OfferCodeEditor",
  "homepage-pods": "HomepagePodEditor",
};

const specialMenus = {
  customers: "CustomerDataViewer",
  allocateoffer: "OfferAllocator",
};

export const UseUserRoles = () => {
  const { userRoles } = useSessionContext();
  return userRoles;
};

export const HasReadOnlyAccess = () => {
  const userRoles = UseUserRoles();
  return userRoles.includes("ReadOnly");
};

export const HasRequiredRoleAccess = (menu) => {
  const userRoles = UseUserRoles();
  const requiredRole = roleMapping[menu];
  return requiredRole && userRoles.includes(requiredRole);
};

export const HasSpecialMenuAccess = (menu) => {
  const userRoles = UseUserRoles();
  const requiredRole = specialMenus[menu];
  return requiredRole && userRoles.includes(requiredRole);
};

export const ValidatePermission = (menu) => {
  const readOnlyRole = HasReadOnlyAccess();
  const validRole = HasRequiredRoleAccess(menu);
  return readOnlyRole ? roleMapping[menu] : validRole;
};

export const GetAccounts = () => {
  const { accounts } = useMsal();
  return accounts;
};
