import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { GridItem, GridWrapper } from "@jsluna/grid";
import { Cancel } from "@jsluna/icons";
import {
  ButtonGroupPrimary,
  ButtonGroupWrapper,
  FilledButton,
  Link,
  Modal,
  ModalHeading,
  OutlinedButton,
  Table,
  TextButton,
} from "@jsluna/react";

import OfferCodeClient from "../../services/OfferCodeClient";

const offerCodeClient = new OfferCodeClient();

const DashboardTable = ({ activityLabels, setPageRefresh, isOCEditor }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const handleCancel = (id) => {
    setOpenModal(true);
    setSelectedId(id);
  };

  const deleteActivityLabel = async () => {
    await offerCodeClient
      .deleteOfferCode(selectedId)
      .then((res) => {
        if (res.status === 200) {
          navigate("/nectar-activity-labels");
          setPageRefresh(true);
          setOpenModal(false);
          setSelectedId();
          toast.success(
            selectedId + " Nectar activity labels deleted sucessfully"
          );
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <React.Fragment>
      <GridWrapper>
        <GridItem size="1">
          <Table
            sortable
            responsive
            data={activityLabels}
            visuallyHiddenCaption
            columns={[
              {
                name: "Activity label id",
                accessor: (rowData) => ({
                  value: rowData,
                  displayName: rowData.id,
                }),
                sortable: false,
                hideLabel: true,
                className: "customCol",
                render: ({ value }) => (
                  <React.Fragment>
                    <Link
                      color="alpha"
                      bare
                      onClick={() =>
                        navigate(`/nectar-activity-labels/edit/${value.id}`, {
                          state: {
                            editActivityLabels: {
                              enableEdit: true,
                              rowData: activityLabels.filter((data) =>
                                data.id.includes(value.id)
                              ),
                            },
                          },
                        })
                      }
                    >
                      {value.id}
                    </Link>
                  </React.Fragment>
                ),
              },
              {
                name: "Display type",
                accessor: (rowData) => ({ value: rowData.displayType }),
                sortable: false,
              },
              {
                name: "Details",
                accessor: (rowData) => ({
                  value: rowData.details,
                }),
                sortable: false,
              },
              {
                name: "Description",
                accessor: (rowData) => ({
                  value: rowData.description,
                }),
                sortable: false,
              },
              {
                name: "Cancel",
                sortable: false,
                accessor: (rowData) => ({ value: rowData }),
                hideLabel: true,
                render: ({ value }) => (
                  <OutlinedButton
                    disabled={!isOCEditor}
                    onClick={() => handleCancel(value.id)}
                  >
                    <Cancel />
                  </OutlinedButton>
                ),
              },
            ]}
          />
        </GridItem>
      </GridWrapper>
      <Modal
        fullScreen
        restrictClose
        alert
        handleClose={() => setOpenModal(false)}
        open={openModal}
        headingId="dialog-modal"
        className="custom-modal"
      >
        <ModalHeading>Are you sure?</ModalHeading>
        <ButtonGroupWrapper actionbar>
          <ButtonGroupPrimary>
            <TextButton onClick={() => setOpenModal(false)}>Cancel</TextButton>
            <FilledButton
              className="ln-u-margin-left"
              onClick={() => deleteActivityLabel()}
            >
              Delete
            </FilledButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </React.Fragment>
  );
};

export default DashboardTable;
