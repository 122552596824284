export const userGroupTabs = [
  { name: "Create user group", key: "create" },
  { name: "Edit existing user group", key: "edit" },
];

export const options = [
  {
    key: "commit",
    icon: "edit",
    text: `Commit`,
    value: "commit",
  },
  {
    key: "delete",
    icon: "trash",
    text: `Delete`,
    value: "delete",
  },
  {
    key: "ingest",
    icon: "download",
    text: `Ingest`,
    value: "ingest",
  },
  {
    key: "duplicate",
    icon: "repeat",
    text: `Duplicate`,
    value: "duplicate",
  },
];

export const typeOptions = [
  { key: "type1", text: "Type 1", value: "type1" },
  { key: "type2", text: "Type 2", value: "type2" },
  { key: "type3", text: "Type 3", value: "type3" },
  { key: "type4", text: "Type 4", value: "type4" },
  { key: "type5", text: "Type 5", value: "type5" },
];

export const StatusBgColor = {
  orange: "#FD933F",
  blue: "#4490E8",
  amber: "#E2BE00",
  green: "#2E7D32",
  red: "#BD0000",
};

export const RadioButtonData = [
  { value: "allchannel", label: "All Channels", defaultChecked: true },
  { value: "instore", label: "In store" },
  { value: "online", label: "Online" },
];

export const PageSize = 10;

export const Expired = false;

export const formattedDate = (dateString) => {
  let date = new Date(dateString);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};

export const special = [
  "first",
  "second",
  "third",
  "fourth",
  "fifth",
  "sixth",
  "seventh",
  "eighth",
  "ninth",
  "tenth",
  "eleventh",
  "twelfth",
  "thirteenth",
  "fourteenth",
  "fifteenth",
  "sixteenth",
  "seventeenth",
  "eighteenth",
  "nineteenth",
];

export const stringifyNumber = (n) => {
  if (n < 20) return special[n];
};

export const initialTask = {
  trackerName: "",
  pointsOffered: 0,
};

export const PlaceholderFooterImage =
  "https://s3-eu-west-1.amazonaws.com/assets.pianoinfradev.com/images/10x/sainsburys-5x1.png";

export const eventBuilderInitialState = {
  marketingEvent: "",
  trackerName: "",
  trackerType: "TARGET",
  trackerTargetUnitType: "",
  trackerOrder: 1,
  qualifyingSpend: 1,
  skus: [],
};

export const eventOptionsInitialState = {
  optionName: "",
  optionOrder: 1,
  marketingEvent: "",
  mode: "TARGETED",
  pointsValue: 0,
  redemptionPointsValue: 0,
  qualifyingSpend: 0,
  artworkUrl: "http://Url",
};

export const marketingEventUIConfiguration = {
  uiPodType: "",
  summaryPodType: "",
  webPodType: "",
};

export const TrackerTheme = [
  { label: "COREPURPLE", value: "COREPURPLE" },
  { label: "GREEN", value: "GREEN" },
  { label: "YELLOW", value: "YELLOW" },
  { label: "RED", value: "RED" },
  { label: "DARKPUTTY", value: "DARKPUTTY" },
];

export const PlaceholderImage =
  "https://via.placeholder.com/150x150?text=placeholder";

export const TransactionType = [
  { label: "TRANSACTION UNITS", value: "TRANSACTION_UNITS" },
  { label: "TRANSACTION COUNT", value: "TRANSACTION_COUNT" },
  { label: "TRANSACTION SPEND", value: "TRANSACTION_SPEND" },
];

export const CoreLogicType = [
  { label: "Tracker", value: "TRACKER" },
  { label: "Redemption", value: "REDEMPTION" },
  { label: "Flash", value: "INSTANT_REWARD" },
  { label: "Data Playback", value: "DATA_PLAY_BACK" },
  { label: "Custom", value: "CUSTOM" },
];

export const UiOptionsTabs = [
  { name: "Homepage", key: "homepage" },
  { name: "Overview", key: "overview" },
  { name: "Summary", key: "summary" },
];

export const initialSummaryUIOptions = {
  id: "",
  name: "",
  summaryUIFields: {
    podType: "",
  },
};

export const initialHomepageUIOptions = {
  id: "",
  name: "",
  homepageUIFields: {
    podType: "",
  },
};

export const initialOverviewUIOptions = {
  id: "",
  name: "",
  overviewUIFields: {
    podType: "",
    progressSeparator: "",
    showActiveChallengeProgressBar: "",
    showUsedChallengeProgressBar: "",
    showLockedChallengeProgressBar: "",
    showActiveChallengeDetailsProgressBar: "",
    activeTrackerTheme: "COREPURPLE",
    usedTrackerTheme: "COREPURPLE",
    lockedTrackerTheme: "COREPURPLE",
  },
};

export const initialUIContents = {
  contentId: "",
  contentPath: "",
  contentName: "",
  coreLogicType: "",
};

export const iterationsInitialState = {
  id: "",
  marketingEvent: "",
  summaryDuration: 0,
};

export const userGroupCreateOptions = [
  { label: "Browse files", value: "browseCsv" },
  { label: "Input aspire table name", value: "inputTable" },
];
