import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import React, { useEffect, useState } from "react";
import {
  Routes as ReactRoutes,
  Route,
  BrowserRouter as Router,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Loader from "./common/helpers/Loader/Loader";
import {
  getToken,
  isProd,
  login,
  setAuthAccounts,
  setAuthInstance,
} from "./config";
import { useSessionContext } from "./contexts/SessionContext";
import NewSite from "./views/Routes";

const AuthenticatedRoutes = () => {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const { setUserRoles, setIsRoleBasedAccess } = useSessionContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = `${isProd() ? "" : "(Non-prod) "}Nectar management portal`;
    setAuthInstance(instance);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    document.title = `${isProd() ? "" : "(Non-prod) "}Nectar management portal`;
    if (isAuthenticated) {
      setAuthAccounts(accounts);
      if (accessPermittedFlag()) {
        setUserRoles(accounts[0].idTokenClaims.roles);
        setIsRoleBasedAccess(true);
      } else {
        setUserRoles([]);
        setIsRoleBasedAccess(false);
      }
    } else {
      if (!(accounts && accounts[0])) login();
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    const getAzureToken = async () => {
      try {
        const response = await getToken();
        if (response) {
          sessionStorage.setItem(
            "authExpiryTime",
            response?.idTokenClaims?.exp
          );
          setLoading(false); // Stop loading once the token is fetched
        }
      } catch (error) {
        console.error("Error fetching the token: ", error);
        setLoading(false); // Stop loading even if token fetch fails
      }
    };

    if (isAuthenticated && accounts?.length) getAzureToken();
    else setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const accessPermittedFlag = () => {
    return (
      accounts?.[0]?.idTokenClaims?.roles?.includes("AccessPermitted") || false
    );
  };

  if (loading) {
    return <Loader />; // Show loading while waiting for token
  }

  return <NewSite />;
};

const Routes = () => (
  <Router>
    <ReactRoutes>
      <Route path="*" element={<AuthenticatedRoutes />} />
    </ReactRoutes>
    <ToastContainer theme="dark" autoClose={6000} />
  </Router>
);

export default Routes;
