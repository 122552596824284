import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  FilledButton,
  OutlinedButton,
} from "@jsluna/button";
import { Card } from "@jsluna/card";
import { Label, SelectField, TextInputField } from "@jsluna/form";
import { GridItem, GridWrapper } from "@jsluna/grid";

import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import OfferCodeClient from "../../services/OfferCodeClient";

const offerCodeClient = new OfferCodeClient();

const NectarActivityLabelEditor = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [labels, setLabels] = useState({});
  const { editActivityLabels } = location.state || {};
  const [isEdit, setIsEdit] = useState(false);

  const isOCEditor = HasRequiredRoleAccess("offercodes");

  useEffect(() => {
    if (editActivityLabels && editActivityLabels.enableEdit) {
      setLabels(editActivityLabels.rowData[0]);
      setIsEdit(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleLabels = (event) => {
    const { name, value } = event.target;
    setLabels((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const hasAllKeys = () => {
    let flag = true;
    if (
      labels.id &&
      labels.displayType &&
      labels.details &&
      labels.description
    ) {
      flag = false;
    }
    if (!isOCEditor) {
      flag = true;
    }
    return flag;
  };

  const handleCreate = async () => {
    await offerCodeClient
      .createOrUpdateOfferCode(labels)
      .then((res) => {
        if (res.status === 200) {
          navigate("/nectar-activity-labels");
          toast.success(
            labels.id + " Nectar activity labels creted sucessfully"
          );
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
      <GridWrapper matrix className="ln-u-margin-top*3">
        <GridItem>
          <Label htmlFor="marketing-events" className="ln-u-margin-left">
            Nectar Activity Labels
          </Label>
          <br />
          <br />
          <GridItem size={"4/5"}>
            <GridItem size="1/2">
              <TextInputField
                name="id"
                label="Label Id"
                placeholder="eg:- CFC23"
                value={labels.id}
                onChange={(e) => handleLabels(e)}
              />
            </GridItem>
            <GridItem size="1/2">
              <TextInputField
                name="headerText"
                label="Title"
                placeholder="eg:- CFC23"
                value={labels.headerText}
                onChange={(e) => handleLabels(e)}
              />
            </GridItem>
            <GridItem size="1/2">
              <SelectField
                name="displayType"
                label="Display type"
                options={[
                  { label: "Normal", value: "NORMAL" },
                  { label: "Bonus", value: "BONUS" },
                  { label: "Base rate", value: "BASERATE" },
                ]}
                value={labels.displayType}
                onChange={(e) => handleLabels(e)}
              />
            </GridItem>
            <GridItem size="1/2" />
            <GridItem size="1/2">
              <TextInputField
                name="details"
                label="Details"
                placeholder="eg:- CFC"
                value={labels.details}
                onChange={(e) => handleLabels(e)}
              />
            </GridItem>
            <GridItem size="1/2">
              <TextInputField
                name="description"
                label="Description"
                placeholder="eg:- test/F_V_TEST"
                value={labels.description}
                onChange={(e) => handleLabels(e)}
              />
            </GridItem>
            <GridItem size="1/2">
              <ButtonGroupWrapper>
                <ButtonGroupSecondary>
                  <OutlinedButton
                    type="button"
                    size="small"
                    onClick={() => navigate("/nectar-activity-labels")}
                  >
                    Cancel
                  </OutlinedButton>
                  &nbsp;&nbsp;
                  <FilledButton
                    primary
                    size="small"
                    type="submit"
                    onClick={() => handleCreate()}
                    disabled={hasAllKeys()}
                  >
                    {isEdit ? "Save" : "Create"}
                  </FilledButton>
                </ButtonGroupSecondary>
              </ButtonGroupWrapper>
            </GridItem>
          </GridItem>
        </GridItem>
      </GridWrapper>
    </Card>
  );
};

export default NectarActivityLabelEditor;
