import axios from "axios";
import { defaults, reduce, size } from "lodash";

import { getToken } from "../config";

/**
 * Upstream customer sign-up api context path configuration
 * @param {string} path - the path to append to the context
 * @returns {string} the path with context prefixed
 */
export const managementApi = (path) => `/management-portal-api${path}`;

/**
 * Enumeration to track ajax request states
 */
export const LoadingState = {
  SUCCESS: "SUCCESS",
  IN_PROGRESS: "IN_PROGRESS",
  FAILED: "FAILED",
  PENDING: "PENDING",
};

/**
 * Http verbs
 */
export const Method = {
  GET: "GET",
  POST: "POST",
  DELETE: "DELETE",
  PUT: "PUT",
  PATCH: "PATCH",
};

/**
 * Http headers
 */
export const Header = {
  CONTENT_TYPE: "Content-Type",
  ACCEPT: "Accept",
};

/**
 * Http method types
 */
export const MediaType = {
  JSON: "application/json",
  MultipartFormData: "multipart/form-data",
};

export const encodeQuery = (queryMap) => {
  if (queryMap && size(queryMap)) {
    const pairs = reduce(
      queryMap,
      (acc, v, k) => {
        if (v !== undefined && v !== null) {
          acc.push(`${k}=${encodeURIComponent(v)}`);
        }
        return acc;
      },
      []
    );
    if (pairs.length) {
      return `?${pairs.join("&")}`;
    }
  }
  return "";
};

/**
 * Instantiate a http client so we can register interceptors
 */
export const Http = axios.create({});

Http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      window.location = "/404";
    }
    return Promise.reject(error);
  }
);

/**
 * Http client
 */
export class HttpClient {
  constructor(options) {
    this.options = defaults(options, { host: "", headers: {} });
  }
  /**
   * Makes a http request using axios http client
   * @param {string} method - the http verb
   * @param {string} path - the path
   * @param {object?} data - the request body
   * @returns {*}
   */
  request = async (method, path, data, headers) => {
    const resp = await getToken();
    return Http({
      url: path,
      baseURL: this.options.host,
      method: method,
      headers: {
        [Header.ACCEPT]: MediaType.JSON,
        [Header.CONTENT_TYPE]: MediaType.JSON,
        ...{ Authorization: "Bearer " + resp?.idToken },
        ...this.options.headers,
        ...headers,
      },
      data: data,
      credentials: "same-origin",
    });
  };
}
