/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { IconButton } from "@jsluna/button";
import { Card } from "@jsluna/card";
import { GridItem, GridWrapper } from "@jsluna/grid";
import { ListView, Plus } from "@jsluna/icons";
import { ProgressBar, ProgressIndicator } from "@jsluna/progress";

import { InlineGroup } from "../../common/components/InlineGroup";
import { PageHeader } from "../../common/components/PageHeader";
import { HasRequiredRoleAccess } from "../../common/userPermissionsCheck";
import OfferCodeClient from "../../services/OfferCodeClient";
import DashboardTable from "./DashboardTable";

const offerCodeClient = new OfferCodeClient();

const NectarActivityLabels = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [activityLabels, setActivityLabels] = useState([]);
  const [pageRefresh, setPageRefresh] = useState(false);

  const isOCEditor = HasRequiredRoleAccess("offercodes");

  useEffect(() => {
    loadActivityLabels();
  }, [pageRefresh]);

  const loadActivityLabels = async (page) => {
    setLoading(true);
    await offerCodeClient
      .fetchOfferCodes()
      .then(({ data }) => {
        setLoading(false);
        setActivityLabels(data);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  return (
    <>
      <Card style={{ border: "1px solid #737373", borderRadius: "3px" }}>
        <GridWrapper matrix>
          <GridItem size="1/1">
            <PageHeader>
              <ListView />
              Nectar Activity Labels
            </PageHeader>
          </GridItem>
          <GridItem size="4/5" className="ln-u-margin-top">
            <InlineGroup>
              <IconButton
                variant="filled"
                element="button"
                label="Create Activity Label"
                onClick={() => navigate("/nectar-activity-labels/edit/new")}
                disabled={!isOCEditor}
              >
                <Plus />
              </IconButton>
            </InlineGroup>
          </GridItem>
          <GridItem size="1/1">
            <Card>
              <DashboardTable
                activityLabels={activityLabels}
                setPageRefresh={setPageRefresh}
                isOCEditor={isOCEditor}
              />
            </Card>
          </GridItem>
        </GridWrapper>
      </Card>
      {loading && (
        <ProgressIndicator page loading preventFocus>
          <ProgressBar color="light" />
          Loading...
        </ProgressIndicator>
      )}
    </>
  );
};

export default NectarActivityLabels;
